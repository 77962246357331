import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Token } from "../../../type/token";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../bootstrap/Modal"
import moment from "moment";
import { CardBody } from "../../bootstrap/Card";
import Input from "../../bootstrap/forms/Input";
import Button from "../../bootstrap/Button";
import { getUserGrantTokens, recreateGrantOrCheckInToken, updateTokenById } from "../../../services/token/token-service";
import showNotification from "../../extras/showNotification";
import { UserContext } from "../../../contexts/user-context";
import Swal from "sweetalert2";
import { TOKEN_STATUS } from "../token/token-activation-form/blocks/constants";

type EditTokenFormProps = {
    token: Token | null;
    setIsOpenEditTokenForm: (isOpen: boolean) => void;
    isOpenEditTokenForm: boolean;
    setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditTokenForm = ({ token, isOpenEditTokenForm, setIsOpenEditTokenForm, setRefreshTable }: EditTokenFormProps) => {
	const { user } = useContext(UserContext);
    const [expiresAtDateTime, setExpiresAtDateTime] = useState<string>(moment(token?.expiresAt).format('YYYY-MM-DD'));
    const [isSaving, setIsSaving] = useState<boolean>(false);
    
    useEffect(() => {
        if (token) {
            setExpiresAtDateTime(moment(token.expiresAt).format('YYYY-MM-DD'));
        }
    }, [token])

    const onChangeExpireAtDateTime = (event: ChangeEvent<HTMLInputElement>) => {
        const newDate = moment(event.currentTarget.value).format('YYYY-MM-DD');
        setExpiresAtDateTime(newDate);
    }

    const handleSave = async () => {
        if (!token) {
            showNotification('Acesso não encontrado', "", 'danger');
            return;
        }

        if (moment(expiresAtDateTime).isBefore(token.expiresAt) || moment(expiresAtDateTime).isBefore(moment().format('YYYY-MM-DD'))) {
            showNotification('A data de expiração não pode ser menor que a data atual', "", 'danger');
            return;
        }

        setIsSaving(true);

        const loggedUserTokens = await getUserGrantTokens(user.id)
        const loggedUserGrantToken = loggedUserTokens.results.find((t) => 
            t.status === TOKEN_STATUS.ACTIVE
            && t.buildingId === token.buildingId
            && t.spotId === token.spotId
        )

        if (!loggedUserGrantToken) {
            showNotification('Usuário não tem permissão para alterar o token', "", 'danger');
            setIsSaving(false);
            return;
        }

        if (moment(expiresAtDateTime).isAfter(loggedUserGrantToken.expiresAt)) {
            showNotification(
                `A data de expiração não pode ser maior que a sua data de expiração (${moment(loggedUserGrantToken.expiresAt).format('DD/MM/YYYY')})`,
                "",
                'danger'
            );
            setIsSaving(false);
            return;
        }


        if (token?.status === TOKEN_STATUS.ACTIVE) {
            Swal.fire({
                title: 'ATENÇÃO',
                html:
                    '<span class"fs-3">Você confirma a alteração?</span><br/><br/>' +
                    '<ul class="fs-5" style="text-align: left!important;">' +
                    `<li>${token.userRequested !== null ? token.userRequested.name.toUpperCase() : "Usuário sem nome"}</li>` +
                    '<li>Alteração de data de expiração para: <strong>' + moment(expiresAtDateTime).format('DD/MM/YYYY') + '</strong></li>' +
                    '</ul>',
                customClass: {
                    container: 'bg-dark',
                    htmlContainer: 'text-left',
                    confirmButton: 'bg-info',
                },
                showDenyButton: true,
                confirmButtonText: 'Confirmar',
                allowOutsideClick: false,
                denyButtonText: `Cancelar`,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    showNotification(`Salvando alterações`, `Aguarde um momento`, 'info');

                    const expiresAtWithoutTimezone = moment(expiresAtDateTime).endOf('day').format('YYYY-MM-DD[T]HH:mm:ss+00:00');

                    await updateTokenById({
                        tokenId: token.id,
                        modifiedAt: moment().toISOString(),
                        modifiedBy: user.id,
                        reasonAccess: token.tag || null,
                        expiresAt: expiresAtWithoutTimezone,
                    })

                    showNotification(`Alterações salvas com sucesso`, ``, 'success');
                    setRefreshTable((prev) => !prev);
                    setIsOpenEditTokenForm(false);
                } else {
                    showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
                }
            }).catch((error) => {
                showNotification(`Erro ao salvar as alterações`, ``, 'danger');
            }).finally(() => {
                setIsSaving(false);
            })
        } else {
            Swal.fire({
                title: 'ATENÇÃO',
                html:
                    '<span class"fs-3">Você confirma a reativação?</span><br/><br/>' +
                    '<ul class="fs-5" style="text-align: left!important;">' +
                    `<li>${token.userRequested !== null ? token.userRequested.name.toUpperCase() : "Usuário sem nome"}</li>` +
                    '<li>Nova data de expiração em: <strong>' + moment(expiresAtDateTime).format('DD/MM/YYYY') + '</strong></li>' +
                    '</ul>',
                customClass: {
                    container: 'bg-dark',
                    htmlContainer: 'text-left',
                    confirmButton: 'bg-info',
                },
                showDenyButton: true,
                confirmButtonText: 'Confirmar',
                allowOutsideClick: false,
                denyButtonText: `Cancelar`,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    showNotification(`Salvando alterações`, `Aguarde um momento`, 'info');

                    const expiresAtWithoutTimezone = moment(expiresAtDateTime).endOf('day').format('YYYY-MM-DD[T]HH:mm:ss+00:00');

                    await recreateGrantOrCheckInToken({
                        authorizationType: token.authorizationType,
                        buildingId: token.buildingId,
                        expirationDate: expiresAtWithoutTimezone,
                        issuedFor: token.issuedFor,
                        loggedUserId: user.id,
                        role: token.role!,
                        spotId: token.spotId,
                        tag: token.tag!,
                    })

                    showNotification(`Alterações salvas com sucesso`, ``, 'success');
                    setRefreshTable((prev) => !prev);
                    setIsOpenEditTokenForm(false);
                } else {
                    showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
                }
            }).catch((error) => {
                showNotification(`Erro ao salvar as alterações`, ``, 'danger');
            }).finally(() => {
                setIsSaving(false);
            })
        }
    }

    return (
        <Modal
            setIsOpen={setIsOpenEditTokenForm}
            isOpen={isOpenEditTokenForm}
            titleId='edit-token-form'
            isScrollable
            isStaticBackdrop
            isCentered
            size={'lg'}
        >
            <ModalHeader setIsOpen={setIsOpenEditTokenForm}>
                <ModalTitle id='edit-token-form'>
                    Editar Acesso de {token?.userRequested?.name}
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div>
                    <CardBody isScrollable={false}>
                        <div>
                            <h4>Data Fim</h4>
                            <Input
                                onChange={onChangeExpireAtDateTime}
                                value={expiresAtDateTime}
                                type='date'
                                style={{
                                    backgroundColor: 'transparent!important',
                                    borderColor: 'transparent!important',
                                }}
                            />
                        </div>

                        <div className='d-flex justify-content-end mt-3'>
                            <Button
                                color='info'
                                icon='Save'
                                onClick={() => handleSave()}
                                isDisable={isSaving}
                            >
                                Salvar
                            </Button>
                        </div>
                    </CardBody>
                </div>
            </ModalBody>
        </Modal>
    )
}