    import { useContext, useEffect, useRef, useState } from "react"
    import { UserContext } from "../../../../contexts/user-context"
    import { getBuildingSpotsList } from "../../../../services/spot/spot-service"
    import { getAllTokensByBuildingId, getTokensIssuedForUserGrantAsSpotList } from "../../../../services/token/token-service"
    import { SpotList } from "../../../../type/spot"
    import Card, { CardBody } from "../../../bootstrap/Card"
    import { AUTH_TYPES, TOKEN_STATUS, getReasonAccessText } from "../../token/token-activation-form/blocks/constants"
    import { ArrowDown, ArrowUp } from "../../../icon/bootstrap"

    interface OptionType {
        label: string
        value: string
        disabled?: boolean
    }

    interface MultiSelectProps {
        options: OptionType[]
        onChange: (selected: OptionType[]) => void
    }

    const MultiSelect: React.FC<MultiSelectProps> = ({ options, onChange }) => {
        const [isOpen, setIsOpen] = useState(false)
        const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([])
        const [searchTerm, setSearchTerm] = useState("")
        const dropdownRef = useRef<HTMLDivElement>(null)

        const toggleDropdown = () => setIsOpen((prev) => !prev)

        const handleOptionClick = (option: OptionType) => {
            if (option.disabled) return
            const exists = selectedOptions.some((o) => o.value === option.value)
            let newSelection: OptionType[]
            if (exists) {
                newSelection = selectedOptions.filter((o) => o.value !== option.value)
            } else {
                newSelection = [...selectedOptions, option]
            }
            setSelectedOptions(newSelection)
            onChange(newSelection)
        }

        const isSelected = (value: string) => selectedOptions.some((o) => o.value === value)

        const filteredOptions = options.filter((option) =>
            option.label.toLowerCase().includes(searchTerm.toLowerCase())
        )

        useEffect(() => {
            function handleClickOutside(event: MouseEvent) {
                if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                    setIsOpen(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside)
            return () => {
                document.removeEventListener("mousedown", handleClickOutside)
            }
        }, [])

        return (
            <div ref={dropdownRef} style={{ position: "relative", width: "100%" }}>
                <div
                    onClick={toggleDropdown}
                    style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        cursor: "pointer",
                        borderRadius: "4px",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <span>{selectedOptions.length ? selectedOptions.map((o) => o.label).join(", ") : "Selecione..."}</span>
                    <span>{isOpen ? <ArrowUp/> : <ArrowDown/>}</span>
                </div>

                {isOpen && (
                    <div
                        style={{
                            position: "relative",
                            top: "5px",
                            background: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            zIndex: "1000",
                            maxHeight: "300px",
                            overflowY: "auto",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            width: "100%",
                        }}
                    >
                        <input
                            type="text"
                            placeholder="Pesquise..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{
                                width: "100%",
                                padding: "10px",
                                boxSizing: "border-box",
                                border: "none",
                                borderBottom: "1px solid #ccc",
                                outline: "none",
                            }}
                        />

                        {filteredOptions.map((option) => (
                            <div
                                key={option.value}
                                onClick={() => handleOptionClick(option)}
                                style={{
                                    padding: "10px",
                                    cursor: option.disabled ? "not-allowed" : "pointer",
                                    background: isSelected(option.value) ? "#007bff" : "#fff",
                                    color: isSelected(option.value) ? "#fff" : "#000",
                                    pointerEvents: option.disabled ? "none" : "auto",
                                    opacity: option.disabled ? 0.6 : 1,
                                }}
                            >
                                {option.label}
                            </div>
                        ))}

                        {filteredOptions.length === 0 && (
                            <div
                                style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    color: "#999",
                                }}
                            >
                                No options found
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }

    interface TargetSpotTokenActivationProps {
        buildingId: number
        setTargetSpot: React.Dispatch<React.SetStateAction<Array<string>>>
        targetSpot: Array<string>
        reasonAccess: string
        canGrantAccess: string
    }

    export function TargetSpotTokenActivation({
        buildingId,
        setTargetSpot,
        targetSpot,
        reasonAccess,
        canGrantAccess
    }: TargetSpotTokenActivationProps) {
        const { user } = useContext(UserContext)
        const [spots, setSpots] = useState<SpotList>([])
        const [isLoading, setIsLoading] = useState(false)

        useEffect(() => {
            async function getSpotsOwner() {
                const response = await getTokensIssuedForUserGrantAsSpotList(user.id)
                setSpots(response)
            }

            async function getSpots() {
                setIsLoading(true)
                const allSpotsOfBuildingToken = await getBuildingSpotsList(buildingId)
                const allTokensOfBuildingToken = await getAllTokensByBuildingId(buildingId)
                const allNotAvailableSpotIds = allTokensOfBuildingToken.results
                    .filter((token) =>
                        token.authorizationType === AUTH_TYPES.GRANT &&
                        (token.status === TOKEN_STATUS.ACTIVE || token.status === TOKEN_STATUS.PENDING)
                    )
                    .map((token) => token.spotId)
                if (allNotAvailableSpotIds.length > 0) {
                    const allSpotsOfBuildingTokenWithOccupation = allSpotsOfBuildingToken
                        .filter((spot) => spot.spotType === "House" || spot.spotType === "Apartment")
                        .map((spot) => {
                            const available = !allNotAvailableSpotIds.includes(spot.id)
                            return { ...spot, available }
                        })
                    setSpots(allSpotsOfBuildingTokenWithOccupation)
                }
                setIsLoading(false)
            }

            if (reasonAccess !== "owner" && canGrantAccess === "YES") {
                getSpotsOwner()
            } else {
                getSpots()
            }
        }, [reasonAccess, canGrantAccess, buildingId, user.id])

        const handleMultiSetTargetSpot = (selected: OptionType[]) => {
            console.log(selected)

            setTargetSpot(selected.map((s) => s.value))
        }

        return (
            <Card>
                <CardBody>
                    <div className="row">
                        {spots.length > 0 && reasonAccess !== "owner" && (
                            <>
                                <div className="fw-bold fs-5 me-2 d-flex justify-content-center">
                                    Qual a Unidade?
                                </div>
                                <div className="d-flex justify-content-center mt-3">
                                    <MultiSelect
                                        options={spots.map((spot) => ({
                                            label: `${spot.name} ${!spot.available ? "(Ocupado)" : ""}`,
                                            value: String(spot.id),
                                            disabled: spot.available === false
                                        }))}
                                        onChange={handleMultiSetTargetSpot}
                                    />
                                </div>
                            </>
                        )}

                        {spots.length <= 0 && reasonAccess !== "owner" && reasonAccess !== "staff" && (
                            <>
                                <div className="fw-bold fs-5 me-2 d-flex justify-content-center text-center">
                                    Nenhum token ativo do tipo Proprietário encontrado.
                                </div>
                                <small className="text-center text-primary fs-6">
                                    Para continuar a ativação de um {getReasonAccessText(reasonAccess)} como alguém que possa autorizar em seu nome,
                                    você precisa ter um token ativo do tipo Proprietário para algum Spot.
                                </small>
                            </>
                        )}

                        {spots.length > 0 && reasonAccess === "owner" && (
                            <>
                                <div className="fw-bold fs-5 me-2 d-flex justify-content-center">
                                    Qual a Unidade?
                                </div>
                                <div className="d-flex justify-content-center mt-3" style={{ position: "relative", width: "100%" }}>
                                    <MultiSelect
                                        options={spots.map((spot) => ({
                                            label: `${spot.name} ${!spot.available ? "(Ocupado)" : ""}`,
                                            value: String(spot.id),
                                            disabled: spot.available === false
                                        }))}
                                        onChange={handleMultiSetTargetSpot}
                                    />
                                </div>
                            </>
                        )}

                        {spots.length <= 0 && reasonAccess === "owner" && !isLoading && (
                            <>
                                <div className="fw-bold fs-5 me-2 d-flex justify-content-center text-center">
                                    Nenhum Lote disponível para utilizar. Entre em contato com o suporte Entrada Segura.
                                </div>
                            </>
                        )}

                        {isLoading && reasonAccess === "owner" && (
                            <>
                                <div className="fw-bold fs-5 me-2 d-flex justify-content-center text-center">
                                    Carregando lotes disponíveis...
                                </div>
                            </>
                        )}
                    </div>
                </CardBody>
            </Card>
        )
    }
