import _isEmpty from 'lodash/isEmpty';
import { AUTH_TYPES, TOKEN_STATUS, TOKEN_TYPES, USER_ROLES_LOWER } from '../../components/entrada-segura/token/token-activation-form/blocks/constants';

import { BuildingList } from '../../type/building';
import { SpotList } from '../../type/spot';
import { Token, TokenAuthChain, TokenInactivationSummaryList, TokenList } from '../../type/token';
import api from '../api';
import { HTTPBuildingListResponse } from '../building/http-building-response';
import { parseBuilding } from '../building/parsers/building-parser';

import {
	HTTPActivationDetails,
	HTTPTokenAuthChain, HTTPTokenListResponse, HTTPTokenResponse, HTTPTokenSummaryList
} from './http-token-response';
import { parseTokenAuthChain } from './parsers/token-auth-chain-parser';
import { parseTokenInactivationSummary } from './parsers/token-inactivation-summary-parser';
import { parseToken } from './parsers/token-parser';
import { parseTokenToSpotsList } from './parsers/token-to-spot-parser';
import { ENV, N8N_ACTIVATION_DETAILS_FLOW_URL } from '../constants';

export async function getTokenById(token?: string): Promise<Token> {
	return api.get(`/api/v1/tokens/${token}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenResponse;

		return parseToken(responseJson);
	});
}

interface UpdateTokenByIdParams {
	reasonAccess: string | null;
	tokenId: string;
	authorizationType?: string;
	role?: string;
	expiresAt?: string;
	modifiedAt: string;
	modifiedBy: number;
	issuedAt?: string;
	status?: string;
}

export async function recreateGrantOrCheckInToken({
	buildingId, 
	loggedUserId,
	spotId,
	issuedFor,
	tag,
	authorizationType,
	role,
	expirationDate
}: {
	buildingId: number;
	spotId: number;
	loggedUserId: number;
	issuedFor: number;	
	tag: string;
	authorizationType: string;
	role: string;
	expirationDate: string;
}) {
	// Create Invite Token
	const inviteTokenBody = {
		authorization_type: AUTH_TYPES.INVITE,
		building_id: buildingId,
		spot_id: spotId,
		issued_by: loggedUserId,
		created_by: loggedUserId,
	}

	const inviteToken = await api.post(`/api/v1/tokens/`, JSON.stringify(inviteTokenBody), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenResponse;

		return parseToken(responseJson);
	});

	// Fetches register token
	const [registerToken] = await api.get(`/api/v1/tokens/issued-for/${loggedUserId}?status=active&token_type=register`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return responseJson.results.map(parseToken);
	});

	// Create activate token
	const createActivateTokenBody = {
		authorization_type: AUTH_TYPES.ACTIVATE,
		building_id: buildingId,
		spot_id: spotId,
		issued_by: loggedUserId,
		issued_for: issuedFor,
		created_by: loggedUserId,
		token_id: registerToken.id,
		status: TOKEN_STATUS.PENDING,
	}

	const activateToken = await api.post(`/api/v1/tokens/`, JSON.stringify(createActivateTokenBody), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenResponse;

		return parseToken(responseJson);
	});

	// Activate token
	const activateTokenBody = {
		id: activateToken.id,
		status: TOKEN_STATUS.ACTIVE,
		modified_by: loggedUserId,
		tag,
	}

	await api.put(`/api/v1/tokens/`, JSON.stringify(activateTokenBody), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	})

	// Create grant or checkin token
	const createGrantOrCheckInTokenBody = {
		tag,
		authorization_type: authorizationType,
		building_id: buildingId,
		spot_id: spotId,
		issued_by: loggedUserId,
		issued_for: issuedFor,
		created_by: loggedUserId,
		token_id: inviteToken.id,
		status: TOKEN_STATUS.ACTIVE,
		role,
		expires_at: expirationDate,
		issued_at: new Date().toISOString(),
	}

	await api.post(`/api/v1/tokens/`, JSON.stringify(createGrantOrCheckInTokenBody), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenResponse;

		return parseToken(responseJson);
	});

	console.log('Tokens created successfully');
}

export async function updateTokenById({
	reasonAccess,
	tokenId,
	authorizationType,
	role,
	expiresAt,
	modifiedAt,
	modifiedBy,
	issuedAt,
	status,
}: UpdateTokenByIdParams): Promise<Token> {
	const body = {
		tag: reasonAccess,
		id: tokenId,
		authorization_type: authorizationType,
		expires_at: expiresAt,
		role,
		modified_at: modifiedAt,
		modified_by: modifiedBy,
		issued_at: issuedAt,
		status,
	};

	return api.put(`/api/v1/tokens/`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenResponse;

		return parseToken(responseJson);
	});
}

interface GetTokensParams {
	currentPage?: number;
	perPage?: number;
}
export async function getTokens({ currentPage = 1, perPage = 10 }: GetTokensParams): Promise<TokenList> {
	return api.get(`/api/v1/tokens?page=${currentPage}&size=${perPage}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return {
			pageNumber: responseJson.pageNumber,
			pageSize: responseJson.pageSize,
			totalRecords: responseJson.totalRecords,
			results: responseJson.results.map(parseToken),
		};
	}).catch(error => {
		throw error;
	});
}

export async function getTokensIssuedForUserAsSpotList(userId: number): Promise<SpotList> {
	return api.get(`/api/v1/tokens/issued-for/${userId}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		const filteredTokens = responseJson.results.filter((token) =>
			(!(token.spot_id === null && token.role === 'guest')))

		return filteredTokens.map(parseTokenToSpotsList);
	});
}

export async function getTokensIssuedForUserThatHaveGrantAccessAsSpotList(userId: number): Promise<SpotList> {
	return api.get(`/api/v1/tokens/issued-for/${userId}?size=1000`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		const filteredTokens = responseJson.results.filter((token) => (
			!(token.spot_id === null && token.role === 'guest') 
				&& token.authorization_type === AUTH_TYPES.GRANT 
				&& token.status === TOKEN_STATUS.ACTIVE)
		)

		return filteredTokens.map(parseTokenToSpotsList);
	});
}

export async function getTokensIssuedForUserGrantAsSpotList(userId: number): Promise<SpotList> {
	return api.get(`/api/v1/tokens/issued-for/${userId}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		const filteredTokens = responseJson.results.filter((token) =>
			(token.spot_id !== null && token.role === 'grant' && token.status === TOKEN_STATUS.ACTIVE))

		return filteredTokens.map(parseTokenToSpotsList);
	});
}

export async function getTokensGrantedUserAsSpotList(userId: number): Promise<SpotList> {
	return api.get(`/api/v1/tokens/issued-for/${userId}?token_type=grant`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		const filteredTokens = responseJson.results.filter(
			(token) => (token.spot_id !== null))

		return filteredTokens.map(parseTokenToSpotsList);
	});
}

interface GetTokensIssuedByUserParams {
	userId: number;
	page?: number;
	perPage?: number;
}
export async function getTokensIssuedByUser({
	userId,
	page = 1,
	perPage = 10,
}: GetTokensIssuedByUserParams): Promise<TokenList> {
	return api.get(`/api/v1/tokens/issued-by/${userId}?page=${page}&size=${perPage}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return {
			pageNumber: responseJson.pageNumber,
			pageSize: responseJson.pageSize,
			totalRecords: responseJson.totalRecords,
			results: responseJson.results.map(parseToken),
		};
	});
}

interface GetAllTokensByAuthorizationTypesParams {
	page?: number;
	perPage?: number;
	authorizationTypes: Array<string>;
}
export async function getAllTokensByAuthorizationTypes({
	page = 1,
	perPage = 10,
	authorizationTypes
}: GetAllTokensByAuthorizationTypesParams): Promise<TokenList> {
	const body = {
		"filter": [
			{
				"key": "authorization_type",
				"operation": "in",
				"value": authorizationTypes
			}
		]
	};

	return api.post(`/api/v1/tokens/dynamic-filter?page=${page}&size=${perPage}`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return {
			pageNumber: responseJson.pageNumber,
			pageSize: responseJson.pageSize,
			totalRecords: responseJson.totalRecords,
			results: responseJson.results.map(parseToken),
		};
	});
}

interface GetTokensIssuedByUserAuthorizationTypesParams {
	userId: number;
	page?: number;
	perPage?: number;
	authorizationTypes: Array<string>;
	buildingId: number[];
	userRequestedName?: string;
}
export async function getTokensIssuedByUserAuthorizationTypes({
	userId,
	page = 1,
	perPage = 10,
	authorizationTypes,
	buildingId,
	userRequestedName
}: GetTokensIssuedByUserAuthorizationTypesParams): Promise<TokenList> {
	const body: {
		filter: {
			key: string;
			operation: string;
			value: any;
		}[]
	} = {
		"filter": [
			{
				"key": "authorization_type",
				"operation": "in",
				"value": authorizationTypes
			},
			{
				"key": "issued_by",
				"operation": "in",
				"value": userId
			},
			{
				"key": "building_id",
				"operation": "in",
				"value": buildingId
			},
		]
	};

	if (userRequestedName) {
		body.filter.push({
			"key": "user_requested.name",
			"operation": "ilike",
			"value": `%${userRequestedName}%`
		})
	}

	return api.post(`/api/v1/tokens/dynamic-filter?page=${page}&size=${perPage}`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return {
			pageNumber: responseJson.pageNumber,
			pageSize: responseJson.pageSize,
			totalRecords: responseJson.totalRecords,
			results: responseJson.results.map(parseToken),
		};
	});
}

interface GetTokensIssuedForUserAuthorizationTypesParams {
	userId: number;
	page?: number;
	perPage?: number;
	authorizationTypes: Array<string>;
}
export async function getTokensIssuedForUserAuthorizationTypes({
	userId,
	page = 1,
	perPage = 10,
	authorizationTypes
}: GetTokensIssuedForUserAuthorizationTypesParams): Promise<TokenList> {
	const body = {
		"filter": [
			{
				"key": "authorization_type",
				"operation": "in",
				"value": authorizationTypes
			},
			{
				"key": "issued_for",
				"operation": "in",
				"value": userId
			},
		]
	};

	return api.post(`/api/v1/tokens/dynamic-filter?page=${page}&size=${perPage}`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return {
			pageNumber: responseJson.pageNumber,
			pageSize: responseJson.pageSize,
			totalRecords: responseJson.totalRecords,
			results: responseJson.results.map(parseToken),
		};
	});
}

interface GetTokensIssuedByUserAndChildrenAuthorizationTypesParams {
	userId: number[];
	page?: number;
	perPage?: number;
	authorizationTypes: Array<string>;
	buildingId: number[];
	userRequestedName?: string;
}
export async function getTokensIssuedByUserAndChildrenAuthorizationTypes({
	userId,
	page = 1,
	perPage = 10,
	authorizationTypes,
	buildingId,
	userRequestedName
}: GetTokensIssuedByUserAndChildrenAuthorizationTypesParams): Promise<TokenList> {
	const body: {
		filter: {
			key: string;
			operation: string;
			value: any;
		}[]
	} = {
		"filter": [
			{
				"key": "authorization_type",
				"operation": "in",
				"value": authorizationTypes
			},
			{
				"key": "issued_by",
				"operation": "in",
				"value": userId
			},
			{
				"key": "building_id",
				"operation": "in",
				"value": buildingId
			}
		]
	};

	if (userRequestedName) {
		body.filter.push({
			"key": "user_requested.name",
			"operation": "ilike",
			"value": `%${userRequestedName}%`
		})
	}

	return api.post(`/api/v1/tokens/dynamic-filter?page=${page}&size=${perPage}`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return {
			pageNumber: responseJson.pageNumber,
			pageSize: responseJson.pageSize,
			totalRecords: responseJson.totalRecords,
			results: responseJson.results.map(parseToken),
		};
	});
}

interface GetTokensByAuthorizationTypesParams {
	page?: number;
	perPage?: number;
	authorizationTypes: string[];
	status: string;
	buildingId?: number; // Adicionado o parâmetro "building" à interface
  }
  
  export async function getTokensByAuthorizationTypes({
	page = 1,
	perPage = 100000,
	authorizationTypes,
	status,
	buildingId, // Adicionado o parâmetro "building" à função
  }: GetTokensByAuthorizationTypesParams): Promise<TokenList> {
	const body = {
	  filter: [
		{
		  key: "authorization_type",
		  operation: "in",
		  value: authorizationTypes,
		},
		{
		  key: "status",
		  operation: "eq",
		  value: status,
		},
		{
		  key: "building_id",
		  operation: "eq",
		  value: buildingId, // Usar o valor passado para "building"
		},
	  ],
	};
  
	return api
	  .post(
		`/api/v1/tokens/dynamic-filter?page=${page}&size=${perPage}`,
		JSON.stringify(body),
		{
		  headers: {
			'Content-type': 'application/json; charset=UTF-8',
		  },
		}
	  )
	  .then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;
  
		return {
		  pageNumber: responseJson.pageNumber,
		  pageSize: responseJson.pageSize,
		  totalRecords: responseJson.totalRecords,
		  results: responseJson.results.map(parseToken),
		};
	  });
  }
  

interface GetTokensActivateParams {
	page?: number;
	perPage?: number;
	issuedBy: number;
}
export async function getTokensActivate({
	page = 1,
	perPage = 100000,
	issuedBy,
}: GetTokensActivateParams): Promise<TokenList> {
	const body = {
		"filter": [
			{
				"key": "issued_by",
				"operation": "eq",
				"value": issuedBy
			},
			{
				"key": "authorization_type",
				"operation": "eq",
				"value": AUTH_TYPES.ACTIVATE
			},
			{
				"key": "status",
				"operation": "eq",
				"value": TOKEN_STATUS.PENDING
			},
		]
	};

	return api.post(`/api/v1/tokens/dynamic-filter?page=${page}&size=${perPage}`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return {
			pageNumber: responseJson.pageNumber,
			pageSize: responseJson.pageSize,
			totalRecords: responseJson.totalRecords,
			results: responseJson.results.map(parseToken),
		};
	});
}

interface GetTokensAnalyzeParams {
	page?: number;
	perPage?: number;
	status: string;
}
export async function getTokensAnalyze({
	page = 1,
	perPage = 100000,
	status,
}: GetTokensAnalyzeParams): Promise<TokenList> {
	const body = {
		"filter": [
			{
				"key": "authorization_type",
				"operation": "eq",
				"value": AUTH_TYPES.QUALIFY
			},
			{
				"key": "status",
				"operation": "eq",
				"value": status
			},
		]
	};

	return api.post(`/api/v1/tokens/dynamic-filter?page=${page}&size=${perPage}`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return {
			pageNumber: responseJson.pageNumber,
			pageSize: responseJson.pageSize,
			totalRecords: responseJson.totalRecords,
			results: responseJson.results.map(parseToken),
		};
	});
}

interface GetTokensByTodayDateAuthorizationTypesParams {
	today: string;
	page?: number;
	perPage?: number;
	authorizationTypes: Array<string>;
	superUser: boolean;
	buildingId: number;
}
export async function getTokensByTodayDateAuthorizationTypes({
	today,
	page = 1,
	perPage = 10,
	authorizationTypes,
	superUser,
	buildingId
}: GetTokensByTodayDateAuthorizationTypesParams): Promise<TokenList> {
	let body = {};
	if (superUser) {
		body = {
			"filter": [
				{
					"key": "authorization_type",
					"operation": "in",
					"value": authorizationTypes
				},
				{
					"key": "requested_at",
					"operation": "ilike",
					"value": `%${today}%`
				}
			]
		};
	} else {
		body = {
			"filter": [
				{
					"key": "authorization_type",
					"operation": "in",
					"value": authorizationTypes
				},
				{
					"key": "requested_at",
					"operation": "ilike",
					"value": `%${today}%`
				},
				{
					"key": "building_id",
					"operation": "eq",
					"value": `${buildingId}`
				}
			]
		};
	}

	return api.post(`/api/v1/tokens/dynamic-filter?page=${page}&size=${perPage}`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return {
			pageNumber: responseJson.pageNumber,
			pageSize: responseJson.pageSize,
			totalRecords: responseJson.totalRecords,
			results: responseJson.results.map(parseToken),
		};
	});
}

interface GetAllTokensByTodayDateAuthorizationTypesParams {
	today: string;
	page?: number;
	perPage?: number;
	authorizationTypes: Array<string>;
	superUser: boolean;
	buildingId: number;
}
export async function getAllTokensByTodayDateAuthorizationTypes({
	today,
	page = 1,
	perPage = 10000,
	authorizationTypes,
	superUser,
	buildingId
}: GetAllTokensByTodayDateAuthorizationTypesParams): Promise<TokenList> {
	let body = {};

	if (superUser) {
		body = {
			"filter": [
				{
					"key": "authorization_type",
					"operation": "in",
					"value": authorizationTypes
				},
				{
					"key": "requested_at",
					"operation": "ilike",
					"value": `%${today}%`
				}
			]
		};
	}
	else {
		body = {
			"filter": [
				{
					"key": "authorization_type",
					"operation": "in",
					"value": authorizationTypes
				},
				{
					"key": "requested_at",
					"operation": "ilike",
					"value": `%${today}%`
				},
				{
					"key": "building_id",
					"operation": "eq",
					"value": `${buildingId}`
				}
			]
		};
	}

	return api.post(`/api/v1/tokens/dynamic-filter?page=${page}&size=${perPage}`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return {
			pageNumber: responseJson.pageNumber,
			pageSize: responseJson.pageSize,
			totalRecords: responseJson.totalRecords,
			results: responseJson.results.map(parseToken),
		};
	});
}

interface GetTokensByUserRequestedParams {
	page?: number;
	perPage?: number;
	taxids: (string | null)[];
}
export async function getTokensByUserRequested({ page = 1, perPage = 10000, taxids }: GetTokensByUserRequestedParams): Promise<TokenList> {
	const body = {
		"filter": [
			{
				"key": "user_requested.taxid",
				"operation": "in",
				"value": taxids
			},
			{
				"key": "authorization_type",
				"operation": "in",
				"value": ["grant", "checkin"]
			},
			{
				"key": "status",
				"operation": "eq",
				"value": "active"
			}
		]
	};

	return api.post(`/api/v1/tokens/dynamic-filter?page=${page}&size=${perPage}`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return {
			pageNumber: responseJson.pageNumber,
			pageSize: responseJson.pageSize,
			totalRecords: responseJson.totalRecords,
			results: responseJson.results.map(parseToken),
		};
	})
}

export async function getAllTokensByBuildingId(buildingId: number): Promise<TokenList> {
	const body = {
		"filter": [
			{
				"key": "building_id",
				"operation": "eq",
				"value": `${buildingId}`
			}
		]
	};

	return api.post(`/api/v1/tokens/dynamic-filter?page=${1}&size=${10000}`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return {
			pageNumber: responseJson.pageNumber,
			pageSize: responseJson.pageSize,
			totalRecords: responseJson.totalRecords,
			results: responseJson.results.map(parseToken),
		};
	})
}

export async function getUserGrantTokens(userId: number): Promise<TokenList> {
	return api.get(`/api/v1/tokens/issued-for/${userId}?status=active&token_type=grant`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return {
			pageNumber: responseJson.pageNumber,
			pageSize: responseJson.pageSize,
			totalRecords: responseJson.totalRecords,
			results: responseJson.results.map(parseToken),
		};
	});
}

interface GetUserHighestRoleParams {
	userId: number;
}
export async function getUserHighestRole({
	userId
}: GetUserHighestRoleParams): Promise<string> {
	return api.get(`/api/v1/tokens/issued-for/${userId}?token_type=grant`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;
		const tokens = responseJson.results.map(parseToken)
		const tokensForBuildingAndSpot = tokens

		if (_isEmpty(tokensForBuildingAndSpot)) {
			return USER_ROLES_LOWER.GUEST
		} else {
			let highestRole = USER_ROLES_LOWER.ACCESS_MANAGER

			tokensForBuildingAndSpot.forEach((token) => {
				if (token.role === USER_ROLES_LOWER.SYSADMIN) {
					highestRole = USER_ROLES_LOWER.SYSADMIN;
				} else if (token.role === USER_ROLES_LOWER.CONTRACT_MANAGER) {
					highestRole = USER_ROLES_LOWER.CONTRACT_MANAGER;
				} else if (token.role === USER_ROLES_LOWER.STAFF) {
					highestRole = USER_ROLES_LOWER.STAFF;
				} else if (token.role === USER_ROLES_LOWER.SPOT_MANAGER && highestRole === USER_ROLES_LOWER.ACCESS_MANAGER) {
					highestRole = USER_ROLES_LOWER.SPOT_MANAGER
				}
			})

			return highestRole;
		}
	});
}

export async function getTokensToActivate(userId: number): Promise<TokenList> {
	return api.get(`/api/v1/tokens/issued-by/${userId}?status=pending`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return {
			pageNumber: responseJson.pageNumber,
			pageSize: responseJson.pageSize,
			totalRecords: responseJson.totalRecords,
			results: responseJson.results.map(parseToken),
		};
	});
}

export async function getTokenInactivationSummary(tokenId: string): Promise<TokenInactivationSummaryList> {
	return api.get(`/api/v1/tokens/inactivate/${tokenId}/summary`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenSummaryList;

		return responseJson.summary.map(parseTokenInactivationSummary);
	});
}

export async function inactivateToken(tokenId: string): Promise<void> {
	api.post(`api/v1/tokens/inactivate/${tokenId}`, null, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		if (response.status === 200) {
			return
		}
	});
}

export async function getTokensIssuedForUser(userId: string): Promise<SpotList> {
	return api.get(`/api/v1/tokens/issued-for/${userId}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return responseJson.results.map(parseTokenToSpotsList);
	});
}

export async function getTokenDescendants(tokenId: string): Promise<TokenAuthChain> {
	return api.get(`/api/v1/tokens/authorization-chain/descendant/${tokenId}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenAuthChain;

		return responseJson.map(parseTokenAuthChain);
	});
}

export async function getTokenAuthorizationChain(tokenId: string): Promise<TokenAuthChain> {
	return api.get(`/api/v1/tokens/authorization-chain/${tokenId}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenAuthChain;

		return responseJson.map(parseTokenAuthChain);
	});
}

export async function getBuildingsByUser(userId: number): Promise<BuildingList> {
	return api.get(`/api/v1/tokens/user/${userId}/buildings?status=active`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPBuildingListResponse;

		return responseJson.results.map(parseBuilding);
	});
}

export async function getBuildingsTokensByUser(userId: number): Promise<Array<Token>> {
	return api.get(`/api/v1/tokens/issued-for/${userId}?status=active&page=1&size=100`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return responseJson.results.map(parseToken);
	});
}

interface GetTokensBySpotIdProps {
	spotId: number
	page?: number;
	perPage?: number;
	authorizationTypes: Array<string>;
}

export async function getTokensBySpotId({
	spotId,
	page,
	perPage,
	authorizationTypes
}: GetTokensBySpotIdProps): Promise<TokenList> {
	const body = {
		"filter": [
			{
				"key": "spot_id",
				"operation": "eq",
				"value": spotId
			},
			{
				"key": "authorization_type",
				"operation": "in",
				"value": authorizationTypes
			}
		]
	};

	return api.post(`/api/v1/tokens/dynamic-filter?page=${page}&size=${perPage}`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return {
			pageNumber: responseJson.pageNumber,
			pageSize: responseJson.pageSize,
			totalRecords: responseJson.totalRecords,
			results: responseJson.results.map(parseToken),
		};
	});
}

export async function getHighestRoleByUserId(userId: number) {
	return api.get(`/api/v1/tokens/hightest-token/${userId}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data;

		const valores = Object.values(responseJson);
		const highestRole: any = valores.flat().find((item: any) => item.index === 1)

		if (highestRole?.role) {
			return highestRole.role
		}

		return ""
	})
		.catch(() => {
			return ""
		})
}

export async function getHighestTagByUserId(userId: number) {
	try {
		const response = await api.get(`/api/v1/tokens/hightest-token/${userId}`, {
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
			}
		})

		const tokenData: any = Object.values(response.data).flat().find((item: any) => item.index === 1)

		const token = await getTokenById(tokenData.id)

		return token.tag
	} catch (error) {
		return ""
	}
}

export async function getActivationDetails(tokenId: string) {
	const url = N8N_ACTIVATION_DETAILS_FLOW_URL;

	return api.post(url, 
		JSON.stringify({ token_id: tokenId }), 
		{
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
			},
		}
	).then((response) => {
		const responseJson = response.data as HTTPActivationDetails;

		return responseJson;
	})
}

export async function getInactiveTokensByUserByBuilding(userTaxId: string, buildingId: number) {
	const body = {
		"filter": [
			{
				"key": "building_id",
				"operation": "eq",
				"value": buildingId
			},
			{
				"key": "authorization_type",
				"operation": "eq",
				"value": AUTH_TYPES.QUALIFY
			},
			{
				"key": "status",
				"operation": "eq",
				"value": TOKEN_STATUS.INACTIVE
			},
			{
				"key": "user_requested.taxid",
				"operation": "eq",
				"value": userTaxId
			},
		]
	}

	return api.post(`/api/v1/tokens/dynamic-filter`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return {
			pageNumber: responseJson.pageNumber,
			pageSize: responseJson.pageSize,
			totalRecords: responseJson.totalRecords,
			results: responseJson.results.map(parseToken),
		};
	});
}

interface CreateTokenParams {
	reasonAccess: string | null;
	spotId?: number | null;
	buildingId: number;
	issuedFor?: number | null;
	issuedBy: number;
	issuedAt: string;
	authorizationType: string;
	expiresAt?: string;
	expiresCount?: number;
	role: string;
	subject?: string | null;
	createdBy?: number | null;
	createdAt?: string | null;
	status?: string;
}
export async function createToken({
	reasonAccess,
	spotId,
	buildingId,
	issuedBy,
	authorizationType,
	expiresAt,
	role,
	subject,
	issuedAt,
	issuedFor,
	createdBy,
	createdAt,
	expiresCount,
	status = TOKEN_STATUS.ACTIVE,
}: CreateTokenParams): Promise<Token> {
	const body = {
		tag: reasonAccess,
		issued_by: issuedBy,
		issued_at: issuedAt,
		issued_for: issuedFor,
		spot_id: spotId,
		building_id: buildingId,
		authorization_type: authorizationType,
		expires_at: expiresAt,
		expires_count: expiresCount,
		role,
		subject,
		status,
		whatsapp_message: 'Olá quero concluir o meu check-in: #{token_id}#',
		create_link: true,
		created_by: createdBy,
		created_at: createdAt,
	};

	return api.post(`/api/v1/tokens/`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		if (response) {
			const responseJson = response.data as HTTPTokenResponse;
			return parseToken(responseJson);
		}

		throw new Error();
	});
}

export async function getTokenAcestors(tokenId: string): Promise<Array<Token>> {
	return api.get(`/api/v1/tokens/authorization-chain/ancestor/${tokenId}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as Array<HTTPTokenResponse>;

		return responseJson.map(parseToken);
	});
}

interface getTokenGrantOrCheckInFromTokenQualifyProps {
	token: Token;
}

export async function getTokenGrantOrCheckInFromTokenQualify({ token }: getTokenGrantOrCheckInFromTokenQualifyProps): Promise<TokenList> {
	const body = {
		"filter": [
			{
				"key": "building_id",
				"operation": "eq",
				"value": token.buildingId
			},
			{
				"key": "spot_id",
				"operation": "eq",
				"value": token.spotId
			},
			{
				"key": "authorization_type",
				"operation": "in",
				"value": [AUTH_TYPES.CHECKIN, AUTH_TYPES.GRANT]
			},
			{
				"key": "user_requested.taxid",
				"operation": "eq",
				"value": token.userRequested.taxid
			},
			{
				"key": "user_issued.taxid",
				"operation": "eq",
				"value": token.userIssued.taxid
			}
		]
	}

	return api.post(`/api/v1/tokens/dynamic-filter`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPTokenListResponse;

		return {
			pageNumber: responseJson.pageNumber,
			pageSize: responseJson.pageSize,
			totalRecords: responseJson.totalRecords,
			results: responseJson.results.map(parseToken),
		};
	});
}