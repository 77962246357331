import classNames from 'classnames';

import moment from 'moment';
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import Swal from 'sweetalert2';
import { UserContext } from '../../../contexts/user-context';
import useDarkMode from '../../../hooks/useDarkMode';
import * as SlackService from "../../../services/slack/handle-notifications-slack";
import { getActivationDetails, updateTokenById } from '../../../services/token/token-service';
import { Token } from "../../../type/token";
import Button from "../../bootstrap/Button";
import Card, { CardBody } from "../../bootstrap/Card";
import Modal, { ModalBody, ModalHeader } from "../../bootstrap/Modal";
import showNotification from '../../extras/showNotification';
import { AUTH_TYPES, getCanGrantAccessText, getReasonAccessText, TOKEN_STATUS, USER_ROLES_LOWER } from '../token/token-activation-form/blocks/constants';
import { handleTokenActivation } from './handle-activation';
import { HeaderTokenActivation } from "./header-token-activation";
import { ProvideGrantAccess } from "./spot-manager/provide-grant-access";
import { useBuildingData } from '../../../hooks/entrada-segura/use-building-data';
import { TargetSpotTokenActivation } from './contract-manager/target-spot-token-activation';
import { getSpotById, getSpotByType } from '../../../services/spot/spot-service';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Label from '../../bootstrap/forms/Label';
import Input from '../../bootstrap/forms/Input';
import Checks from '../../bootstrap/forms/Checks';
import { TargetSpotTokenActivationForSeasonalService } from './contract-manager/target-spot-token-activation-for-service-season';

interface TokenToActivateSpotManager {
    token: Token;
    setShouldShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
    setErrorAlertMessage: React.Dispatch<React.SetStateAction<string>>;
    setErrorAlertTitle: React.Dispatch<React.SetStateAction<string>>;
    setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
    refreshList: boolean;
    expiresAtLoggedUser: string | null;
    issuedAtLoggedUser: string | null;
    loggedUserTagToken?: string;
}

export function TokenToActivate({ loggedUserTagToken, token, setShouldShowErrorAlert, setErrorAlertMessage, setErrorAlertTitle, setRefreshList, refreshList, expiresAtLoggedUser, issuedAtLoggedUser }: TokenToActivateSpotManager) {
    const { user } = useContext(UserContext);
    const { darkModeStatus } = useDarkMode();
    const building = useBuildingData({ buildingId: token.buildingId })

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [reasonAccess, setReasonAccess] = useState<string>("");
    const [expiresAt, setExpiresAt] = useState<string>("");
    const [startsAt, setStartsAt] = useState<string>(moment().startOf('day').format('YYYY-MM-DD'))
    const [canGrantAccess, setCanGrantAccess] = useState<string>("");
    const [showWarningGrant, setShowWarningGrant] = useState<boolean>(false);
    const [withoutExpiration, setWithoutExpiration] = useState<boolean>(false);
    const [destination, setDestination] = useState<string>("");
    const [targetSpot, setTargetSpot] = useState<string[]>([]);
    const [tokenOriginRole, setTokenOriginRole] = useState<string>("");
    const [dateFieldStartsAtDateTime, setDateFieldStartsAtDateTime] = useState<string>(startsAt);
    const [dateFieldExpiresAtDateTime, setDateFieldExpiresAtDateTime] = useState<string>("");
    const [buildingHasAccessManager, setBuildingHasAccessManager] = useState<boolean>(true);
    const [reasons, setReasons] = useState<{
        owner: boolean
        staff: boolean
        visitor: boolean
        service: boolean
        domestic_service: boolean
        quick_service: boolean
        realtor: boolean
        event: boolean
        rent: boolean
        family: boolean
        delivery: boolean
        service_season: boolean
        can_authorize: boolean
    }>({
        owner: false,
        staff: false,
        visitor: false,
        service: false,
        domestic_service: false,
        quick_service: false,
        realtor: false,
        event: false,
        rent: false,
        family: false,
        delivery: false,
        service_season: false,
        can_authorize: false
    })

    const hasOptionToGrant = reasons.can_authorize 
        && (tokenOriginRole === USER_ROLES_LOWER.CONTRACT_MANAGER || tokenOriginRole === USER_ROLES_LOWER.SPOT_MANAGER || tokenOriginRole === USER_ROLES_LOWER.STAFF)
        && (reasonAccess === 'visitor' || reasonAccess === 'service' || reasonAccess === 'domestic_service' || reasonAccess === 'family' || reasonAccess === 'rent' || reasonAccess === 'realtor' || reasonAccess === 'service_season')
        && buildingHasAccessManager;

    useEffect(() => {
        getActivationDetails(token.id).then((data) => {
            setReasons(data.reasons)
            setDestination(`${data.building.name} | ${data.spot.name}`)
            setTokenOriginRole(data.grant.role)
            setBuildingHasAccessManager(data.building.has_access_manager)
        })
    }, [token])

    useEffect(() => {
        if (canGrantAccess === "YES") {
            setShowWarningGrant(true);
        } else {
            setShowWarningGrant(false);
        }
    }, [canGrantAccess])

    useEffect(() => {
        setTargetSpot([]);
        setExpiresAt("");
        setWithoutExpiration(false);
        setStartsAt(moment().startOf('day').format('YYYY-MM-DD'));
        setCanGrantAccess(hasOptionToGrant ? "" : "NO");
        setShowWarningGrant(false);
    }, [reasonAccess, reasons, hasOptionToGrant])

    useEffect(() => {
        if (token.tag === 'delivery') {
            setReasonAccess('delivery')
        }
    }, [token.tag])

    const onChangeExpireAtDateTime = (event: ChangeEvent<HTMLInputElement>) => {
        const newDate = moment(event.currentTarget.value).endOf("day").format('YYYY-MM-DD');
        setDateFieldExpiresAtDateTime(newDate)
        setExpiresAt(newDate);
    }

    const onChangeStartsAtDateTime = (event: ChangeEvent<HTMLInputElement>) => {
        const newDate = moment(event.currentTarget.value).startOf('day').format('YYYY-MM-DD');
        setDateFieldStartsAtDateTime(newDate)
        setStartsAt(newDate);
    }

    async function handleActivateOwnerToken() {
        console.log("Activate Owner Token")

        setIsSubmitting(true);

        const tokenStartsAt = moment().toISOString(true);
        const authType = AUTH_TYPES.GRANT;
        const tokenRole = USER_ROLES_LOWER.SPOT_MANAGER;
        const spotIds = targetSpot.map((spot) => Number(spot));
        const canGrantAccess = "YES";

        if (spotIds.length === 0 ) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma Unidade de destino!")

            return;
        }

        let arraySpotsName: string[] = [];
        for await (const spotId of spotIds) {
            const spotData = await getSpotById(spotId);
            arraySpotsName.push(spotData.name);
        }

        Swal.fire({
            title: 'ATENÇÃO',
            html:
                '<span class"fs-3">Você confirma a ativação?</span><br/><br/>' +
                '<ul class="fs-5" style="text-align: left!important;">' +
                `<li>${token.userRequested !== null ? token.userRequested.name.toUpperCase() : "Usuário sem nome"}</li>` +
                `<li>Unidade(s): ${arraySpotsName}</li>` +
                `<li>Motivo: ${getReasonAccessText(reasonAccess)}</li>` +
                `<li>Autoriza em seu nome: ${getCanGrantAccessText(canGrantAccess)}</li>` +
                '</ul>',
            customClass: {
                container: 'bg-dark',
                htmlContainer: 'text-left',
                confirmButton: 'bg-info',
            },
            showDenyButton: true,
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            denyButtonText: `Cancelar`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                showNotification('Aguarde, configurando acesso...', '', 'info');

                await handleTokenActivation({
                    reasonAccess,
                    token,
                    issuedAt: tokenStartsAt,
                    loggedUser: user.id,
                    role: tokenRole,
                    buildingId: token.buildingId,
                    spotId: 0,
                    multipleSpotId: spotIds.map(spotId => String(spotId)),
                    authorizationType: authType,
                    setShouldShowErrorAlert,
                    setErrorAlertMessage,
                }).finally(() => {
                    setRefreshList(!refreshList)
                });
            }
            else if (result.isDenied) {
                showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
            }
        }).finally(() => {
            setIsSubmitting(false);
        });
    }

    async function handleActivateStaffToken() {
        console.log("Activate Staff Token")

        setIsSubmitting(true);

        const tokenStartsAt = moment().toISOString(true);
        const tokenWillExpireAt = moment().add(1, 'year').toISOString(true);
        const authType = AUTH_TYPES.GRANT;
        const tokenRole = USER_ROLES_LOWER.STAFF;
        const canGrantAccess = "YES";
        let spotId = token.spotId;
        let arraySpotsName: string[] = [];

        if (tokenOriginRole === USER_ROLES_LOWER.CONTRACT_MANAGER) {
            const adminSpot = await getSpotByType('Administrative', token.buildingId)
            if (!adminSpot) {
                setShouldShowErrorAlert(true);
                setErrorAlertMessage("Não foi possível encontrar a Unidade Administrativa!")

                return;
            }

            spotId = adminSpot[0].id
        }

        if (!spotId) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma Unidade de destino!")

            return;
        }

        const spotData = await getSpotById(spotId);
        arraySpotsName.push(spotData.name);

        Swal.fire({
            title: 'ATENÇÃO',
            html:
                '<span class"fs-3">Você confirma a ativação?</span><br/><br/>' +
                '<ul class="fs-5" style="text-align: left!important;">' +
                `<li>${token.userRequested !== null ? token.userRequested.name.toUpperCase() : "Usuário sem nome"}</li>` +
                `<li>Unidade(s): ${arraySpotsName}</li>` +
                `<li>Motivo: ${getReasonAccessText(reasonAccess)}</li>` +
                `<li>Autoriza em seu nome: ${getCanGrantAccessText(canGrantAccess)}</li>` +
                '</ul>',
            customClass: {
                container: 'bg-dark',
                htmlContainer: 'text-left',
                confirmButton: 'bg-info',
            },
            showDenyButton: true,
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            denyButtonText: `Cancelar`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                showNotification('Aguarde, configurando acesso...', '', 'info');

                await handleTokenActivation({
                    reasonAccess,
                    token,
                    expiresAt: tokenWillExpireAt,
                    issuedAt: tokenStartsAt,
                    loggedUser: user.id,
                    role: tokenRole,
                    buildingId: token.buildingId,
                    spotId,
                    authorizationType: authType,
                    setShouldShowErrorAlert,
                    setErrorAlertMessage,
                }).finally(() => {
                    setRefreshList(!refreshList)
                });
            }
            else if (result.isDenied) {
                showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
            }
        }).finally(() => {
            setIsSubmitting(false);
        })
    }

    async function handleActivateQuickServiceToken() {
        console.log("Activate Quick Service Token")

        setIsSubmitting(true);

        const tokenStartsAt = moment(startsAt).startOf('day').toISOString(true)
        const tokenWillExpireAt = moment(startsAt).endOf('day').toISOString(true)
        const authType = canGrantAccess === "YES" ? AUTH_TYPES.GRANT : AUTH_TYPES.CHECKIN;
        const tokenRole = canGrantAccess === "YES" ? USER_ROLES_LOWER.ACCESS_MANAGER : USER_ROLES_LOWER.GUEST;
        let spotId = token.spotId;
        let arraySpotsName: string[] = [];

        if (tokenOriginRole === USER_ROLES_LOWER.CONTRACT_MANAGER) {
            const adminSpot = await getSpotByType('Administrative', token.buildingId)
            if (!adminSpot) {
                setShouldShowErrorAlert(true);
                setErrorAlertMessage("Não foi possível encontrar a Unidade Administrativa!")

                return;
            }

            spotId = adminSpot[0].id
        }

        if (!spotId) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma Unidade de destino!")

            return;
        }

        if (tokenStartsAt === "" || tokenStartsAt === null) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma data de início para o token!")

            return;
        }

        if (tokenWillExpireAt === "" || tokenWillExpireAt === null) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma data de término para o token!")

            return;
        }

        if (moment(tokenWillExpireAt).isBefore(moment(tokenStartsAt))) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma de término maior ou igual à data de início!")

            return;
        }

        if (issuedAtLoggedUser !== null && moment(tokenStartsAt).isBefore(moment(issuedAtLoggedUser))) {
            setShouldShowErrorAlert(true);
            setErrorAlertTitle("Período inválido!")
            setErrorAlertMessage(`Você não pode ativar um acesso com data inicial fora de seu próprio período. Sua data inicial: ${moment(issuedAtLoggedUser).format('DD/MM/YYYY HH:mm')}. Data inicial do seu convidado: ${moment(tokenStartsAt).format('DD/MM/YYYY HH:mm')}`)

            return;
        }

        if (expiresAtLoggedUser !== null && moment(tokenWillExpireAt).isAfter(moment(expiresAtLoggedUser))) {
            setShouldShowErrorAlert(true);
            setErrorAlertTitle("Período inválido!")
            setErrorAlertMessage(`Você não pode ativar um acesso com data final fora de seu próprio período. Sua data final: ${moment(expiresAtLoggedUser).format('DD/MM/YYYY HH:mm')}. Data final do seu convidado: ${moment(tokenWillExpireAt).format('DD/MM/YYYY HH:mm')}`)

            return;
        }

        if (moment(tokenWillExpireAt).isAfter(moment(tokenStartsAt).add(5, 'day'))) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe um período de até 5 dias entre a data de início e a data de término!")

            return;
        }

        const spotData = await getSpotById(spotId);
        arraySpotsName.push(spotData.name);

        Swal.fire({
            title: 'ATENÇÃO',
            html:
                '<span class"fs-3">Você confirma a ativação?</span><br/><br/>' +
                '<ul class="fs-5" style="text-align: left!important;">' +
                `<li>${token.userRequested !== null ? token.userRequested.name.toUpperCase() : "Usuário sem nome"}</li>` +
                `<li>Unidade(s): ${arraySpotsName}</li>` +
                `<li>Motivo: ${getReasonAccessText(reasonAccess)}</li>` +
                `<li>Autoriza em seu nome: ${getCanGrantAccessText(canGrantAccess)}</li>` +
                '</ul>',
            customClass: {
                container: 'bg-dark',
                htmlContainer: 'text-left',
                confirmButton: 'bg-info',
            },
            showDenyButton: true,
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            denyButtonText: `Cancelar`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                showNotification('Aguarde, configurando acesso...', '', 'info');

                await handleTokenActivation({
                    reasonAccess,
                    token,
                    expiresAt: tokenWillExpireAt,
                    issuedAt: tokenStartsAt,
                    loggedUser: user.id,
                    role: tokenRole,
                    buildingId: token.buildingId,
                    spotId,
                    authorizationType: authType,
                    setShouldShowErrorAlert,
                    setErrorAlertMessage,
                }).finally(() => {
                    setRefreshList(!refreshList)
                });
            }
            else if (result.isDenied) {
                showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
            }
        }).finally(() => {
            setIsSubmitting(false);
        })
    }

    async function handleActivatePartyToken() {
        console.log("Activate Party Token")

        setIsSubmitting(true);

        const tokenStartsAt = moment(startsAt).toISOString(true);
        const tokenWillExpireAt = moment(startsAt).add(1, 'day').toISOString(true);
        const authType = AUTH_TYPES.CHECKIN;
        const tokenRole = USER_ROLES_LOWER.GUEST;
        let spotId = token.spotId;
        let arraySpotsName: string[] = [];

        if (tokenOriginRole === USER_ROLES_LOWER.CONTRACT_MANAGER) {
            const adminSpot = await getSpotByType('Administrative', token.buildingId)
            if (!adminSpot) {
                setShouldShowErrorAlert(true);
                setErrorAlertMessage("Não foi possível encontrar a Unidade Administrativa!")

                return;
            }

            spotId = adminSpot[0].id
        }

        if (!spotId) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma Unidade de destino!")

            return;
        }

        if (tokenStartsAt === "" || tokenStartsAt === null) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma data de início para o token!")

            return;
        }

        if (tokenWillExpireAt === "" || tokenWillExpireAt === null) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma data de término para o token!")

            return;
        }

        if (moment(tokenWillExpireAt).isBefore(moment(tokenStartsAt))) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma de término maior ou igual à data de início!")

            return;
        }

        if (issuedAtLoggedUser !== null && moment(tokenStartsAt).isBefore(moment(issuedAtLoggedUser))) {
            setShouldShowErrorAlert(true);
            setErrorAlertTitle("Período inválido!")
            setErrorAlertMessage(`Você não pode ativar um acesso com data inicial fora de seu próprio período. Sua data inicial: ${moment(issuedAtLoggedUser).format('DD/MM/YYYY HH:mm')}. Data inicial do seu convidado: ${moment(tokenStartsAt).format('DD/MM/YYYY HH:mm')}`)

            return;
        }

        if (expiresAtLoggedUser !== null && moment(tokenWillExpireAt).isAfter(moment(expiresAtLoggedUser))) {
            setShouldShowErrorAlert(true);
            setErrorAlertTitle("Período inválido!")
            setErrorAlertMessage(`Você não pode ativar um acesso com data final fora de seu próprio período. Sua data final: ${moment(expiresAtLoggedUser).format('DD/MM/YYYY HH:mm')}. Data final do seu convidado: ${moment(tokenWillExpireAt).format('DD/MM/YYYY HH:mm')}`)

            return;
        }

        const spotData = await getSpotById(spotId);
        arraySpotsName.push(spotData.name);

        Swal.fire({
            title: 'ATENÇÃO',
            html:
                '<span class"fs-3">Você confirma a ativação?</span><br/><br/>' +
                '<ul class="fs-5" style="text-align: left!important;">' +
                `<li>${token.userRequested !== null ? token.userRequested.name.toUpperCase() : "Usuário sem nome"}</li>` +
                `<li>Unidade(s): ${arraySpotsName}</li>` +
                `<li>Motivo: ${getReasonAccessText(reasonAccess)}</li>` +
                `<li>Autoriza em seu nome: ${getCanGrantAccessText(canGrantAccess)}</li>` +
                '</ul>',
            customClass: {
                container: 'bg-dark',
                htmlContainer: 'text-left',
                confirmButton: 'bg-info',
            },
            showDenyButton: true,
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            denyButtonText: `Cancelar`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                showNotification('Aguarde, configurando acesso...', '', 'info');

                await handleTokenActivation({
                    reasonAccess,
                    token,
                    expiresAt: tokenWillExpireAt,
                    issuedAt: tokenStartsAt,
                    loggedUser: user.id,
                    role: tokenRole,
                    buildingId: token.buildingId,
                    spotId,
                    authorizationType: authType,
                    setShouldShowErrorAlert,
                    setErrorAlertMessage,
                }).finally(() => {
                    setRefreshList(!refreshList)
                });
            }
            else if (result.isDenied) {
                showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
            }
        }).finally(() => {
            setIsSubmitting(false);
        })
    }

    async function handleActivateDeliveryToken() {
        console.log("Activate Delivery Token")

        setIsSubmitting(true);

        const tokenStartsAt = moment(startsAt).toISOString(true);
        const tokenWillExpireAt = moment(startsAt).add(1, 'day').toISOString(true);
        const authType = AUTH_TYPES.CHECKIN;
        const tokenRole = USER_ROLES_LOWER.GUEST;
        let spotId = token.spotId;
        let arraySpotsName: string[] = [];

        if (tokenOriginRole === USER_ROLES_LOWER.CONTRACT_MANAGER) {
            const adminSpot = await getSpotByType('Administrative', token.buildingId)
            if (!adminSpot) {
                setShouldShowErrorAlert(true);
                setErrorAlertMessage("Não foi possível encontrar a Unidade Administrativa!")

                return;
            }

            spotId = adminSpot[0].id
        }

        if (!spotId) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma Unidade de destino!")

            return;
        }

        if (tokenStartsAt === "" || tokenStartsAt === null) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma data de início para o token!")

            return;
        }

        if (tokenWillExpireAt === "" || tokenWillExpireAt === null) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma data de término para o token!")

            return;
        }

        if (moment(tokenWillExpireAt).isBefore(moment(tokenStartsAt))) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma de término maior ou igual à data de início!")

            return;
        }

        if (issuedAtLoggedUser !== null && moment(tokenStartsAt).isBefore(moment(issuedAtLoggedUser))) {
            setShouldShowErrorAlert(true);
            setErrorAlertTitle("Período inválido!")
            setErrorAlertMessage(`Você não pode ativar um acesso com data inicial fora de seu próprio período. Sua data inicial: ${moment(issuedAtLoggedUser).format('DD/MM/YYYY HH:mm')}. Data inicial do seu convidado: ${moment(tokenStartsAt).format('DD/MM/YYYY HH:mm')}`)

            return;
        }

        if (expiresAtLoggedUser !== null && moment(tokenWillExpireAt).isAfter(moment(expiresAtLoggedUser))) {
            setShouldShowErrorAlert(true);
            setErrorAlertTitle("Período inválido!")
            setErrorAlertMessage(`Você não pode ativar um acesso com data final fora de seu próprio período. Sua data final: ${moment(expiresAtLoggedUser).format('DD/MM/YYYY HH:mm')}. Data final do seu convidado: ${moment(tokenWillExpireAt).format('DD/MM/YYYY HH:mm')}`)

            return;
        }

        const spotData = await getSpotById(spotId);
        arraySpotsName.push(spotData.name);

        Swal.fire({
            title: 'ATENÇÃO',
            html:
                '<span class"fs-3">Você confirma a ativação?</span><br/><br/>' +
                '<ul class="fs-5" style="text-align: left!important;">' +
                `<li>${token.userRequested !== null ? token.userRequested.name.toUpperCase() : "Usuário sem nome"}</li>` +
                `<li>Unidade(s): ${arraySpotsName}</li>` +
                `<li>Motivo: ${getReasonAccessText(reasonAccess)}</li>` +
                `<li>Autoriza em seu nome: ${getCanGrantAccessText(canGrantAccess)}</li>` +
                '</ul>',
            customClass: {
                container: 'bg-dark',
                htmlContainer: 'text-left',
                confirmButton: 'bg-info',
            },
            showDenyButton: true,
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            denyButtonText: `Cancelar`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                showNotification('Aguarde, configurando acesso...', '', 'info');

                await handleTokenActivation({
                    reasonAccess,
                    token,
                    expiresAt: tokenWillExpireAt,
                    issuedAt: tokenStartsAt,
                    loggedUser: user.id,
                    role: tokenRole,
                    buildingId: token.buildingId,
                    spotId,
                    authorizationType: authType,
                    setShouldShowErrorAlert,
                    setErrorAlertMessage,
                }).finally(() => {
                    setRefreshList(!refreshList)
                });
            }
            else if (result.isDenied) {
                showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
            }
        }).finally(() => {
            setIsSubmitting(false);
        })
    }

    async function handleActivateSeasonalService() {
        console.log("Activate Seasonal Service Token")

        setIsSubmitting(true);

        const tokenStartsAt = moment(startsAt).toISOString(true);
        const tokenWillExpireAt = moment(expiresAt).toISOString(true);
        const authType = canGrantAccess === "YES" ? AUTH_TYPES.GRANT : AUTH_TYPES.CHECKIN;
        const tokenRole = canGrantAccess === "YES" ? USER_ROLES_LOWER.ACCESS_MANAGER : USER_ROLES_LOWER.GUEST;
        const spotIds = targetSpot.map((spot) => Number(spot));

        if (spotIds.length === 0) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma Unidade de destino!")

            return;
        }

        let arraySpotsName: string[] = [];
        for await (const spotId of spotIds) {
            const spotData = await getSpotById(spotId);
            arraySpotsName.push(spotData.name);
        }

        if (tokenStartsAt === "" || tokenStartsAt === null) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma data de início para o token!")

            return;
        }

        if ((tokenWillExpireAt === "" || tokenWillExpireAt === null)) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma data de término para o token!")

            return;
        }

        if (!withoutExpiration && moment(tokenWillExpireAt).isBefore(moment(tokenStartsAt))) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma de término maior ou igual à data de início!")

            return;
        }

        if (issuedAtLoggedUser !== null && moment(tokenStartsAt).isBefore(moment(issuedAtLoggedUser))) {
            setShouldShowErrorAlert(true);
            setErrorAlertTitle("Período inválido!")
            setErrorAlertMessage(`Você não pode ativar um acesso com data inicial fora de seu próprio período. Sua data inicial: ${moment(issuedAtLoggedUser).format('DD/MM/YYYY HH:mm')}. Data inicial do seu convidado: ${moment(tokenStartsAt).format('DD/MM/YYYY HH:mm')}`)

            return;
        }

        if (expiresAtLoggedUser !== null && moment(tokenWillExpireAt).isAfter(moment(expiresAtLoggedUser))) {
            setShouldShowErrorAlert(true);
            setErrorAlertTitle("Período inválido!")
            setErrorAlertMessage(`Você não pode ativar um acesso com data final fora de seu próprio período. Sua data final: ${moment(expiresAtLoggedUser).format('DD/MM/YYYY HH:mm')}. Data final do seu convidado: ${moment(tokenWillExpireAt).format('DD/MM/YYYY HH:mm')}`)

            return;
        }

        Swal.fire({
            title: 'ATENÇÃO',
            html:
                '<span class"fs-3">Você confirma a ativação?</span><br/><br/>' +
                '<ul class="fs-5" style="text-align: left!important;">' +
                `<li>${token.userRequested !== null ? token.userRequested.name.toUpperCase() : "Usuário sem nome"}</li>` +
                `<li>Unidade(s): ${arraySpotsName}</li>` +
                `<li>Motivo: ${getReasonAccessText(reasonAccess)}</li>` +
                `<li>Autoriza em seu nome: ${getCanGrantAccessText(canGrantAccess)}</li>` +
                '</ul>',
            customClass: {
                container: 'bg-dark',
                htmlContainer: 'text-left',
                confirmButton: 'bg-info',
            },
            showDenyButton: true,
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            denyButtonText: `Cancelar`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                showNotification('Aguarde, configurando acesso...', '', 'info');

                await handleTokenActivation({
                    reasonAccess,
                    token,
                    expiresAt: tokenWillExpireAt,
                    issuedAt: tokenStartsAt,
                    loggedUser: user.id,
                    role: tokenRole,
                    buildingId: token.buildingId,
                    spotId: 0,
                    multipleSpotId: spotIds.map(spotId => String(spotId)),
                    authorizationType: authType,
                    setShouldShowErrorAlert,
                    setErrorAlertMessage,
                }).finally(() => {
                    setRefreshList(!refreshList)
                });
            }
            else if (result.isDenied) {
                showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
            }
        }).finally(() => {
            setIsSubmitting(false);
        })
    }

    async function handleActivateDefaultToken() {
        console.log("Activate Default Token")

        setIsSubmitting(true);

        const tokenStartsAt = moment(startsAt).toISOString(true);
        const tokenWillExpireAt = moment(withoutExpiration ? "" : expiresAt).toISOString(true);
        const authType = canGrantAccess === "YES" ? AUTH_TYPES.GRANT : AUTH_TYPES.CHECKIN;
        const tokenRole = canGrantAccess === "YES" ? USER_ROLES_LOWER.ACCESS_MANAGER : USER_ROLES_LOWER.GUEST;
        let spotId = token.spotId;
        let arraySpotsName: string[] = [];

        if (tokenOriginRole === USER_ROLES_LOWER.CONTRACT_MANAGER) {
            const adminSpot = await getSpotByType('Administrative', token.buildingId)
            if (!adminSpot) {
                setShouldShowErrorAlert(true);
                setErrorAlertMessage("Não foi possível encontrar a Unidade Administrativa!")

                return;
            }

            spotId = adminSpot[0].id
        }

        if (!spotId) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma Unidade de destino!")

            return;
        }

        if (tokenStartsAt === "" || tokenStartsAt === null) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma data de início para o token!")

            return;
        }

        if ((tokenWillExpireAt === "" || tokenWillExpireAt === null) && !withoutExpiration) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma data de término para o token!")

            return;
        }

        if (!withoutExpiration && moment(tokenWillExpireAt).isBefore(moment(tokenStartsAt))) {
            setShouldShowErrorAlert(true);
            setErrorAlertMessage("Por favor, informe uma de término maior ou igual à data de início!")

            return;
        }

        if (issuedAtLoggedUser !== null && moment(tokenStartsAt).isBefore(moment(issuedAtLoggedUser))) {
            setShouldShowErrorAlert(true);
            setErrorAlertTitle("Período inválido!")
            setErrorAlertMessage(`Você não pode ativar um acesso com data inicial fora de seu próprio período. Sua data inicial: ${moment(issuedAtLoggedUser).format('DD/MM/YYYY HH:mm')}. Data inicial do seu convidado: ${moment(tokenStartsAt).format('DD/MM/YYYY HH:mm')}`)

            return;
        }

        if (expiresAtLoggedUser !== null && moment(tokenWillExpireAt).isAfter(moment(expiresAtLoggedUser))) {
            setShouldShowErrorAlert(true);
            setErrorAlertTitle("Período inválido!")
            setErrorAlertMessage(`Você não pode ativar um acesso com data final fora de seu próprio período. Sua data final: ${moment(expiresAtLoggedUser).format('DD/MM/YYYY HH:mm')}. Data final do seu convidado: ${moment(tokenWillExpireAt).format('DD/MM/YYYY HH:mm')}`)

            return;
        }

        const spotData = await getSpotById(spotId);
        arraySpotsName.push(spotData.name);

        Swal.fire({
            title: 'ATENÇÃO',
            html:
                '<span class"fs-3">Você confirma a ativação?</span><br/><br/>' +
                '<ul class="fs-5" style="text-align: left!important;">' +
                `<li>${token.userRequested !== null ? token.userRequested.name.toUpperCase() : "Usuário sem nome"}</li>` +
                `<li>Unidade(s): ${arraySpotsName}</li>` +
                `<li>Motivo: ${getReasonAccessText(reasonAccess)}</li>` +
                `<li>Autoriza em seu nome: ${getCanGrantAccessText(canGrantAccess)}</li>` +
                '</ul>',
            customClass: {
                container: 'bg-dark',
                htmlContainer: 'text-left',
                confirmButton: 'bg-info',
            },
            showDenyButton: true,
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            denyButtonText: `Cancelar`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                showNotification('Aguarde, configurando acesso...', '', 'info');

                await handleTokenActivation({
                    reasonAccess,
                    token,
                    expiresAt: tokenWillExpireAt,
                    issuedAt: tokenStartsAt,
                    loggedUser: user.id,
                    role: tokenRole,
                    buildingId: token.buildingId,
                    spotId,
                    authorizationType: authType,
                    setShouldShowErrorAlert,
                    setErrorAlertMessage,
                }).finally(() => {
                    setRefreshList(!refreshList)
                });
            }
            else if (result.isDenied) {
                showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
            }
        }).finally(() => {
            setIsSubmitting(false);
        })
    }

    async function handleRejectToken() {
        Swal.fire({
            title: 'ATENÇÃO',
            html:
                `<span class"fs-3">Você deseja realmente <span style="color: #e74c3c; font-weight: bold;">REJEITAR</span> o acesso de ${token.userRequested !== null ? token.userRequested.name.toUpperCase() : "Usuário sem nome"}?</span>`,
            customClass: {
                container: 'bg-dark',
                htmlContainer: 'text-left',
                confirmButton: 'bg-info',
            },
            showDenyButton: true,
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            denyButtonText: `Cancelar`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const modifiedAt = moment().toISOString(true);

                await updateTokenById({
                    reasonAccess: token.tag!,
                    tokenId: token.id,
                    modifiedAt,
                    modifiedBy: user.id,
                    status: TOKEN_STATUS.REJECTED,
                })
                    .then(() => {
                        showNotification(`Usuário rejeitado com sucesso!`, ``, 'success');
                        SlackService.HandleRejectTokenSlack({ tokenId: token.id, userRequestedName: token.userRequested.name.toUpperCase() })
                    })
                    .catch(() => {
                        showNotification(`Houve um problema ao rejeitar este usuário!`, ``, 'danger');
                    })
                    .finally(() => {
                        setRefreshList(!refreshList)
                    });
            }
            else if (result.isDenied) {
                showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
            }
        })
    }

    return (
        <>
            <div className='d-flex justify-content-center'>
                <Card
                    className={classNames('', { 'bg-dark': darkModeStatus })}
                    style={{ padding: "10px" }}
                >
                    <CardBody>
                        <div className='row g-5'>
                            {reasonAccess !== 'delivery' && <HeaderTokenActivation
                                token={token}
                                handleRejectToken={handleRejectToken}
                            />}

                            <Card>
                                <CardBody>
                                    <div className='row g-3'>
                                        <div className='fw-bold fs-5 me-2 d-flex justify-content-center'>
                                            Qual o MOTIVO do acesso?
                                        </div>
                                        <div className='col-12'>
                                            <div style={{ display: "grid", gap: "1rem", gridTemplateColumns: "1fr 1fr 1fr", justifyItems: "center", alignItems: "center" }}>
                                                {reasons.owner &&
                                                    <Button
                                                        className='border border-success border-2 text-success fw-bold px-2 ml-2 py-1 rounded-1'
                                                        isLink={reasonAccess !== "owner"}
                                                        onClick={() => { setReasonAccess("owner") }}
                                                    >
                                                        Proprietário
                                                    </Button>
                                                }

                                                {reasons.staff &&
                                                    <Button
                                                        className='border border-success border-2 text-success fw-bold px-2 ml-2 py-1 rounded-1'
                                                        isLink={reasonAccess !== "staff"}
                                                        onClick={() => { setReasonAccess("staff") }}
                                                    >
                                                        Equipe
                                                    </Button>    
                                                }

                                                {reasons.visitor &&
                                                    <Button
                                                        className='border border-success border-2 text-success fw-bold px-2 ml-2 py-1 rounded-1'
                                                        isLink={reasonAccess !== "visitor"}
                                                        onClick={() => { setReasonAccess("visitor") }}
                                                    >
                                                        Visitante
                                                    </Button>
                                                }

                                                {reasons.service &&
                                                    <Button
                                                        className='border border-success border-2 text-success fw-bold px-2 ml-2 py-1 rounded-1'
                                                        isLink={reasonAccess !== "service"}
                                                        onClick={() => { setReasonAccess("service") }}
                                                    >
                                                        Serviço
                                                    </Button>
                                                }

                                                {reasons.domestic_service &&
                                                    <Button
                                                        className='border border-success border-2 text-success fw-bold px-2 ml-2 py-1 rounded-1'
                                                        isLink={reasonAccess !== "domestic_service"}
                                                        onClick={() => { setReasonAccess("domestic_service") }}
                                                    >
                                                        Serviço Doméstico
                                                    </Button>
                                                }

                                                {reasons.quick_service &&
                                                    <Button
                                                        className='border border-success border-2 text-success fw-bold px-2 ml-2 py-1 rounded-1'
                                                        isLink={reasonAccess !== "quick_service"}
                                                        onClick={() => { setReasonAccess("quick_service") }}
                                                    >
                                                        Serviço Rápido
                                                    </Button>
                                                }

                                                {reasons.family &&
                                                    <Button
                                                        className='border border-success border-2 text-success fw-bold px-2 ml-2 py-1 rounded-1'
                                                        isLink={reasonAccess !== "family"}
                                                        onClick={() => { setReasonAccess("family") }}
                                                    >
                                                        Familiar
                                                    </Button>
                                                }

                                                {reasons.event &&
                                                    <Button
                                                        className='border border-success border-2 text-success fw-bold px-2 ml-2 py-1 rounded-1'
                                                        isLink={reasonAccess !== "party"}
                                                        onClick={() => { setReasonAccess("party") }}
                                                    >
                                                        Evento
                                                    </Button>
                                                }

                                                {reasons.rent &&
                                                    <Button
                                                        className='border border-success border-2 text-success fw-bold px-2 ml-2 py-1 rounded-1'
                                                        isLink={reasonAccess !== "rent"}
                                                        onClick={() => { setReasonAccess("rent") }}
                                                    >
                                                        Aluguel
                                                    </Button>
                                                }

                                                {reasons.realtor &&
                                                    <Button
                                                        className='border border-success border-2 text-success fw-bold px-2 ml-2 py-1 rounded-1'
                                                        isLink={reasonAccess !== "realtor"}
                                                        onClick={() => { setReasonAccess("realtor") }}
                                                    >
                                                        Corretor
                                                    </Button>
                                                }

                                                {reasons.service_season && 
                                                    <Button
                                                        className='border border-success border-2 text-success fw-bold px-2 ml-2 py-1 rounded-1'
                                                        isLink={reasonAccess !== "service_season"}
                                                        onClick={() => { setReasonAccess("service_season") }}
                                                    >
                                                        Serviço Temporada
                                                    </Button>
                                                }
                                            </div>

                                            {reasonAccess === 'delivery' && reasons.delivery &&
                                                <div style={{ display: 'flex', justifyContent: 'center'}}>
                                                    <Button
                                                        className='border border-success border-2 text-success fw-bold px-2 ml-2 py-1 rounded-1'
                                                        isLink={reasonAccess === "delivery" ? false : true}
                                                    >
                                                        Tele entrega
                                                    </Button>
                                                </div>
                                            }

                                            <div className='mt-3'>
                                                {reasonAccess === 'service' && <small className="text-center text-primary">IDEAL PARA: obras e manutenção.</small>}
                                                {reasonAccess === 'domestic_service' && <small className="text-center text-primary">IDEAL PARA: serviços recorrentes como zelador, jardineiro, piscineiros, faxina.</small>}
                                                {reasonAccess === 'quick_service' && <small className="text-center text-primary">IDEAL PARA: serviços pontuais como entregas e medicações.</small>}
                                                {reasonAccess === 'party' && <small className="text-center text-primary">IDEAL PARA: eventos e festas.</small>}
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>

                            {reasonAccess === "owner" &&
                                <div className="p-0">
                                    <TargetSpotTokenActivation
                                        buildingId={token.buildingId}
                                        setTargetSpot={setTargetSpot}
                                        targetSpot={targetSpot}
                                        reasonAccess={reasonAccess}
                                        canGrantAccess={canGrantAccess}
                                    />
                                </div>
                            }

                            {reasonAccess === "service_season" &&
                                <div className="p-0">
                                    <TargetSpotTokenActivationForSeasonalService
                                        buildingId={token.buildingId}
                                        setTargetSpot={setTargetSpot}
                                        targetSpot={targetSpot}
                                    />
                                </div>
                            }

                            {reasonAccess && reasonAccess !== "owner" && reasonAccess !== "staff" &&
                                <Card>
                                    <CardBody>
                                        {reasonAccess !== 'delivery' && 
                                            <div className='col-12'>
                                                <div className='fw-bold fs-5 me-2 d-flex justify-content-center'>
                                                    Qual a DATA do acesso?
                                                </div>
                                                <div className='mt-3 d-flex flex-column gap-3'>
                                                    <FormGroup id='startDate' className='mt-2'>
                                                        {reasonAccess !== "quick_service" && reasonAccess !== "party" ?
                                                            <Label className="text-light">
                                                                COMEÇA
                                                            </Label>
                                                            : <></>
                                                        }                                                        
                                                        <Input
                                                            onChange={onChangeStartsAtDateTime}
                                                            value={dateFieldStartsAtDateTime}
                                                            type='date'
                                                            style={{
                                                                backgroundColor: 'transparent!important',
                                                                borderColor: 'transparent!important',
                                                            }}
                                                        />
                                                    </FormGroup>
                                                    {!withoutExpiration && reasonAccess !== 'party' && reasonAccess !== 'quick_service' &&
                                                        <FormGroup id='endDate' className='mt-2'>
                                                            <Label className="text-light">
                                                                TERMINA
                                                            </Label>

                                                            <Input
                                                                onChange={onChangeExpireAtDateTime}
                                                                value={dateFieldExpiresAtDateTime}
                                                                type='date'
                                                                style={{
                                                                    backgroundColor: 'transparent!important',
                                                                    borderColor: 'transparent!important',
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    }

                                                    {reasonAccess === "family" &&
                                                        <div className='mt-3 d-flex flex-row gap-3'>
                                                            <Label className="text-light">
                                                                Sem data de expiração
                                                            </Label>
                                                            <Checks
                                                                type='switch'
                                                                checked={withoutExpiration}
                                                                onChange={() => setWithoutExpiration(!withoutExpiration)}
                                                                style={{
                                                                    backgroundColor: withoutExpiration ? '#FFB0B0' : '#2d3436',
                                                                    borderColor: '#FF0000',
                                                                    borderWidth: '2px',
                                                                    boxShadow: `0 0 0 2px ${withoutExpiration ? '#FF0000' : '#2d3436'}`,
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }

                                        {reasonAccess === "delivery" &&
                                            <div className='col-12 mt-3'>
                                                <div className='fw-bold fs-5 me-2 d-flex justify-content-center'>
                                                    Informações da ENTREGA
                                                </div>
                                                <div className="fw-bold fs-3 d-flex">{token.subject}</div>
                                                <div className="w-normal fs-6 d-flex">Origem</div>
                                                <br />
                                                <div className="w-normal fs-5">{destination}</div>
                                                <div className="w-normal fs-6">Destino</div>
                                                <br />
                                                <div className="w-normal fs-5 d-flex">{moment(token.issuedAt).format('DD/MM/YYYY HH:mm')}</div>
                                                <div className="w-normal fs-6 d-flex">Data solicitação check-in</div>
                                                <br />
                                            </div>
                                        }
                                    </CardBody>
                                </Card>
                            }

                            {reasonAccess === 'delivery' && <HeaderTokenActivation
                                token={token}
                                handleRejectToken={handleRejectToken}
                                reasonAccess={'delivery'}
                            />}

                            {(expiresAt || withoutExpiration) && startsAt && reasonAccess && hasOptionToGrant &&
                                <ProvideGrantAccess
                                    setCanGrantAccess={setCanGrantAccess}
                                    canGrantAccess={canGrantAccess}
                                />
                            }

                            {reasonAccess === "owner" && targetSpot.length > 0 &&
                                <Button
                                    color='primary'
                                    className='w-100'
                                    onClick={() => { handleActivateOwnerToken() }}
                                    isDisable={isSubmitting}
                                >
                                    Confirmar
                                </Button>
                            }

                            {reasonAccess === "staff" &&
                                <Button
                                    color='primary'
                                    className='w-100'
                                    onClick={() => { handleActivateStaffToken() }}
                                    isDisable={isSubmitting}
                                >
                                    Confirmar
                                </Button>
                            }

                            {(expiresAt || withoutExpiration) && canGrantAccess
                                && (reasonAccess === "visitor" 
                                    || reasonAccess === "service"
                                    || reasonAccess === "domestic_service"
                                    || reasonAccess === "family"
                                    || reasonAccess === "rent"
                                    || reasonAccess === "realtor"
                                ) &&
                                <Button
                                    color='primary'
                                    className='w-100'
                                    onClick={() => { handleActivateDefaultToken() }}
                                    isDisable={isSubmitting}
                                >
                                    Confirmar
                                </Button>
                            }

                            {expiresAt 
                                && reasonAccess === "service_season" 
                                && canGrantAccess
                                && targetSpot.length > 0 &&
                                <Button
                                    color='primary'
                                    className='w-100'
                                    onClick={() => { handleActivateSeasonalService() }}
                                    isDisable={isSubmitting}
                                >
                                    Confirmar
                                </Button>
                            }

                            {startsAt && reasonAccess === "quick_service" &&
                                <Button
                                    color='primary'
                                    className='w-100'
                                    onClick={() => { handleActivateQuickServiceToken() }}
                                    isDisable={isSubmitting}
                                >
                                    Confirmar
                                </Button>
                            }

                            {reasonAccess === "party" && startsAt &&
                                <Button
                                    color='primary'
                                    className='w-100'
                                    onClick={() => { handleActivatePartyToken() }}
                                    isDisable={isSubmitting}
                                >
                                    Confirmar
                                </Button>
                            }

                            {reasonAccess === "delivery" && startsAt &&
                                <Button
                                    color='primary'
                                    className='w-100'
                                    onClick={() => { handleActivateDeliveryToken() }}
                                    isDisable={isSubmitting}
                                >
                                    Confirmar
                                </Button>
                            }
                        </div>
                    </CardBody>
                </Card>
            </div>

            <Modal
                setIsOpen={setShowWarningGrant}
                isOpen={showWarningGrant}
                fullScreen={false}
                isCentered
                titleId='transfer-modal'>
                <ModalHeader className='w-100 d-flex justify-content-center align-items-center' setIsOpen={setShowWarningGrant}>
                    <></>
                </ModalHeader>
                <ModalBody className='h-100 d-flex align-items-center justify-content-center'>
                    <div className='row w-100'>
                        <div className='d-flex justify-content-center align-items-center flex-column gap-3'>
                            <h4 className="fw-bold fs-3 text-danger">Atenção!</h4>
                            <div className='text-center'>
                                <p className="fw-bold fs-5">Essa pessoa poderá autorizar acessos em seu nome. Você é o responsável!</p>
                            </div>
                            <Button
                                onClick={() => setShowWarningGrant(false)}
                                color='info'
                            >
                                Entendi
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}